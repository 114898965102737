<template>
  <div class="enlacebloque">
    <b-button :href="enlace" variant="manusaEnlaceBloque">
      <div class="enlacebloque-icono">
        <b-icon icon="person-circle"></b-icon>
      </div>
      <div class="enlacebloque-titulo">{{ titulo }}</div>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "EnlaceBloque",
  props: {
    titulo: String,
    enlace: String,
    icono: String,
  },
};
</script>

<style scoped>
.btn-manusaEnlaceBloque {
  background-color: #fff !important;
  color: var(--color-manusa-azul) !important;
  border-color: var(--color-manusa-azul50) !important;
}
.enlacebloque {
}
.enlacebloque-icono {
  font-size: 10em;
}
.enlacebloque-titulo {
  font-size: 2em;
  font-weight: bold;
}
</style>
