<template>
  <div v-if="autenticado" id="contenido-pagina" class="container">
    <MenuHome></MenuHome>
  </div>
</template>

<script>
import MenuHome from "@/components/MenuHome.vue";
import { mapGetters } from "vuex";
export default {
  name: "Inicio",
  components: {
    MenuHome,
  },
  computed: {
    ...mapGetters(["autenticado"]),
  },
};
</script>
