<template>
  <div>
    <b-row>
      <b-col cols="12" md="4" xl="3">
        <EnlaceBloque
          titulo="MiEspacio. Datos personales actualizados"
          enlace="mi-espacio-actualizaciones"
          icono=""
        ></EnlaceBloque>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EnlaceBloque from "@/components/EnlaceBloque.vue";
export default {
  name: "MenuHome",
  components: {
    EnlaceBloque,
  },
};
</script>

<style></style>
